.wall-theme {
  &.theme-birthday {
    background:linear-gradient(to right, rgba(white, 0.85), rgba(white, 0.85)), url('../../assets/images/bg-birthday-repeat.jpg') repeat;
  }
  &.theme-celebration {
    background:linear-gradient(to right, rgba(white, 0.85), rgba(white, 0.85)), url('../../assets/images/bg-celebration-repeat.jpg') repeat;
  }
  &.theme-memorial {
    background:linear-gradient(to right, rgba(white, 0.8), rgba(white, 0.8)), url('../../assets/images/bg-memorial-repeat.jpg') repeat;
  }
}