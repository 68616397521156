.component-wrap {
  background-color: rgba($wallaby,0.10);
  border:1px solid rgba($wallaby,0.15);
  padding: 50px;
  border-radius: 30px;
}

/* Global User Bar
========================================================= */
.user-bar-entry {
  position: relative;
  display: block;
  color: #666;
  background-color: rgba(white,0.5);
  .tremor-Icon-root  {
    &:hover, &:focus {
      background-color: #ddd;
    }
  }
}

/* User Invites
========================================================= */

.invite-step:not(.activated) {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}