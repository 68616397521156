.confirmMessage-enter {
  opacity: 0;
  transform: scale(0.9);
}
.confirmMessage-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.confirmMessage-exit {
  opacity: 1;
}
.confirmMessage-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

div[class*="confetti-explosion"] {
  > div {
    left:50% !important;
    top:5vw !important;
  }
}