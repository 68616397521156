@import '../../assets/scss/Colors';


.edit_slug_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  margin-bottom: 30px;
}

#set_slug {
  display:flex;
  align-items: center;
  gap:10px;
  input {
    padding: 0 5px;
    background: none;
    margin: 0;
    min-height: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid rgba(black,0.3);
  }
  button {
    margin: 0;
  }
}


.edit_slug {
  display:flex;
}