/* Buttons */

button, .button {
  text-decoration: none;
  font-family: 'umba';
  font-weight: 400;
  letter-spacing:0.07em;
  // font-family: 'Poppins', sans-serif;
  text-transform: none;
}

.text-button {
  background: none !important;
  text-decoration: underline;
  border: none;
  padding: 0;
  font-weight: 100;
  margin-left: 25px;
  font-size: 14px;
  font-family: Arial;
  &:hover, &:focus {
  color: var(--macaron);
  }
}

.remove-comment {
  border-color: #333 !important;
  background-color: #333 !important;
  color: #fff !important;
}