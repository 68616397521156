@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

:root {
  --tandori: #ECA154;
  --macaron: #E56A54;
  --bahama: #7CE0D3;
  --plum: #B55C80;
  --wallaby: #CBC4BC;
  --black: #181c22;
}

@font-face {
  font-family: 'biennale';
  src: url('../fonts/biennale-medium-webfont.woff2') format('woff2'),
       url('../fonts/biennale-medium-webfont.woff') format('woff');
  font-style: normal;
  font-weight: 500;

}

@font-face {
  font-family: 'biennale';
  src: url('../fonts/biennale-thin-webfont.woff2') format('woff2'),
       url('../fonts/biennale-thin-webfont.woff') format('woff');
  font-style: normal;
  font-weight: 300;

}

@font-face {
  font-family: 'umba';
  src: url('../fonts/umbaslab-medium-webfont.woff2') format('woff2'),
       url('../fonts/umbaslab-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;

}

@font-face {
  font-family: 'umba';
  src: url('../fonts/umbaslab-regular-webfont.woff2') format('woff2'),
       url('../fonts/umbaslab-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;

}

body,html {
  min-height: 100%;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #181c22;
  * {
    margin: 0;
    padding: 0;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 25px;
  font-weight: 100;
}

h2 {
  font-size: 30px;
  @apply text-cyan-500;
}

form {
  width: 100%;
  text-align: left;
}

fieldset {
  margin-bottom: 35px;
  border: none;
  padding: 0;
}

label {
  display:block;
  margin-bottom: 10px;
  font-size:14px;
  color: $black;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}

a {
  color:inherit;
  font-weight: 500;
  &:hover, &:focus {
  color: $macaron;
  }
}

